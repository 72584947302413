// @flow
import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import Layout from '../../../components/Layout/Layout';
import ApplySection from '../../../components/Merchant/ApplySection';
import Hero from '../../../components/Merchant/shared/Hero';
import bg from '../../../images/materials_sales.svg';
import bgSp from '../../../images/materials_sales_sp.svg';
import ContactForm from '../../../components/Merchant/SalesContactForm/ContactForm';
import { materialsSalesStyles } from '../../../styles/pages/merchant.styles';

const blocks = [
  {
    title: '新規ユーザーを獲得',
    img: require('../../../images/advantage.svg'),
  },
  {
    title: '後払い決済市場について',
    img: require('../../../images/market.svg'),
  },
  {
    title: '他の決済方法との違い',
    img: require('../../../images/MerchantSales.svg'),
  },
  {
    title: '支払・入金サイクルについて',
    img: require('../../../images/gear.svg'),
  },
];

const SalesPage = () => {
  const classes = materialsSalesStyles({});

  return (
    <Layout isMerchant>
      <Hero title="資料請求" bg={bg} bgSp={bgSp} />
      <>
        <Container maxWidth="md">
          <Box className={classes.wrapper}>
            <Typography
              variant="h2"
              component="h2"
              className={classes.headline}
            >
              資料の内容
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              mt="70px"
              className={classes.section}
            >
              {blocks.map((block) => (
                <Box key={block.title} className={classes.container}>
                  <Box className={classes.item}>
                    <img
                      alt={block.title}
                      src={block.img}
                      className={classes.img}
                    />
                  </Box>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    {block.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
        <Box bgcolor="#F0F0F0" component="section">
          <ContactForm
            description="必要事項をご記入いただくと、資料をダウンロードできます。"
            button="ダウンロード"
            page="merchant"
            mode="white"
          />
        </Box>
      </>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default SalesPage;
