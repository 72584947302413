// @flow
import {
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  Link,
} from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';
import { navigate } from '@reach/router';

import ForwardOutboundLink from '../../shared/ForwardOutboundLink';
import useValidation from '../../../hooks/useValidation';
import validators from '../../../hooks/validators';
import { CONTACT_US_ENDPOINT } from '../../../constants';
import { gtag } from '../../../utils';
import { contactFormFiltersStyles } from '../ContactForm/styles';

const formValidations = {
  lastname: validators.required,
  firstname: validators.required,
  phone: validators.required,
  email: validators.email,
  companyName: validators.required,
};

const ContactForm = ({
  description,
  button,
  page,
  mode,
}: {
  description: string,
  button: string,
  page: string,
  mode?: string,
}) => {
  const classes = contactFormFiltersStyles({});

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [shopsiteURL, setShopsiteURL] = useState('');

  const values = {
    lastname,
    firstname,
    phone,
    email,
    companyName,
  };

  // eslint-disable-next-line
  const { onValidateAll, onValidate, errors, hasError } = useValidation({
    validations: formValidations,
    values,
  });

  const onChangeText = (setter) => (event) => {
    const value = event.target.value;

    setter(event.target.value);
    onValidate({ name: event.target.name, value, checkTouch: true });
  };

  const formattedMessage = () => {
    return {
      form_type: 'request-material',
      name: `${lastname} ${firstname}`,
      phone,
      email,
      corp_name: companyName,
      site_url: shopsiteURL,
    };
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const _errors = onValidateAll({ checkTouch: false });

    if (Object.keys(_errors).find((key) => !!_errors[key])) {
      return;
    }

    setIsFormSubmitting(true);

    axios
      .post(CONTACT_US_ENDPOINT, formattedMessage())
      .then((response) => {
        setIsFormSubmitting(false);
        window.scrollTo(0, 0);

        gtag({
          event: 'Merchant',
          ga_action: 'contact-form-complete',
          ga_label: `${page}-page`,
        });

        if (window.dataLayer) {
          window.dataLayer.push({
            event: `merchant-contact-complete`,
          });
        }

        const { id } = response.data;

        navigate(`/merchant/materials/sales/completed?id=${id}`);
      })
      .catch(() => {
        alert('Unexpected error. Please try again later.'); // eslint-disable-line no-alert
      });
  };

  return (
    <Container maxWidth="md">
      <Box maxWidth="780px" m="0 auto" className={classes.wrapper}>
        <Backdrop open={isFormSubmitting} style={{ zIndex: 20 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <i id="contact_form" className="anchor" />
        <Typography variant="body1" component="h2">
          {description}
        </Typography>
        <Box className={classes.form}>
          <form
            onSubmit={onSubmit}
            noValidate
            className={mode === 'white' ? classes.white : null}
          >
            <Box>
              <TextField
                value={companyName}
                placeholder="企業名を入力してください"
                id="ip_companyName"
                name="companyName"
                label="貴社名"
                variant="filled"
                required
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setCompanyName)}
                fullWidth
                error={!!errors.companyName}
                helperText={errors.companyName}
                className={classes.textField}
              />
            </Box>
            <Box>
              <TextField
                value={lastname}
                placeholder="姓を入力してください"
                id="ip_lastname"
                name="lastname"
                label="お名前 (姓)"
                variant="filled"
                required
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setLastname)}
                fullWidth
                error={!!errors.lastname}
                helperText={errors.lastname}
                className={classes.textField}
              />
            </Box>
            <Box>
              <TextField
                value={firstname}
                placeholder="名を入力してください"
                id="ip_firstname"
                name="firstname"
                label="お名前 (名)"
                variant="filled"
                required
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setFirstname)}
                fullWidth
                error={!!errors.firstname}
                helperText={errors.firstname}
                className={classes.textField}
              />
            </Box>
            <div className="fieldset">
              <TextField
                value={phone}
                placeholder="入力例：0312345678"
                id="ip_phone"
                name="phone"
                label="お電話番号"
                variant="filled"
                required
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setPhone)}
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone}
                className={classes.textField}
              />
            </div>
            <div className="fieldset">
              <TextField
                value={email}
                placeholder="入力例：paidy@example.com"
                id="ip_email"
                name="email"
                label="メールアドレス"
                variant="filled"
                required
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setEmail)}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                className={classes.textField}
              />
            </div>
            <div className="fieldset">
              <TextField
                value={shopsiteURL}
                placeholder="URLを入力してください"
                id="ip_shopsiteURL"
                name="shopsiteURL"
                label="導入検討中のウェブサイトURL"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                onChange={onChangeText(setShopsiteURL)}
                fullWidth
                className={classes.textField}
              />
              <p>※必須</p>
            </div>
            <Box className={classes.note}>
              <Typography variant="body1">
                フォームを送信いただく前に、必ず
                <Link
                  component={ForwardOutboundLink}
                  href="https://terms.paidy.com/docs/consent-clause-regarding-personal-information.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                >
                  個人情報取扱に関する同意書
                </Link>
                をお読みいただき、
                ご同意の上、ご送信ください。お問い合わせでお預かりいたしました個人情報は、
                お問い合わせ対応のみに利用させていただきます。
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box clone width="240px">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {button}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

ContactForm.defaultProps = {
  mode: 'default',
};

export default ContactForm;
